import { render, staticRenderFns } from "./conference.vue?vue&type=template&id=4c634dd7&scoped=true&"
import script from "./conference.vue?vue&type=script&lang=js&"
export * from "./conference.vue?vue&type=script&lang=js&"
import style0 from "./conference.vue?vue&type=style&index=0&id=4c634dd7&lang=scss&scoped=true&"
import style1 from "./conference.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c634dd7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VChip,VForm,VIcon,VRow})
